<template>
  <div style="display: flex">


    <el-container>
      <CommonTree
        :defaultProps="defaultProps"
        :isShowdig="false"
        :searchTitle="searchTitle"
        :showCheckbox="false"
        :treeData="treeData1"
        @getNodeClick="moelClickFn"
        @getTreeAdd="getTreeAdd"
        @getTreeDelete="getTreeDelete"
        @getTreeEdit="getTreeEdit"
      />
      <el-main>
        <head-layout
          :head-btn-options="headBtnOptions"
          :head-title="$t('datamodel.name')"
          @head-add="addFn"
        >
        </head-layout>


        <div class="headers" style="display: flex;">
          <el-input v-model="queryList.tableName" class="new-sino-input" :placeholder="$t('datamodel.label.Dataname')" size="mini" />
          <el-button-group>
            <el-button icon="el-icon-search" size="mini" type="primary" @click="onLoad"></el-button>
            <el-button icon="reset-refresh icon-refresh" size="mini" @click="czFn"></el-button>

          </el-button-group>


        </div>

        <grid-layout
          ref="gridLayout"
          :data-total="total"
          :gridRowBtn="gridRowBtn"
          :page="page"
          :searchColumns="searchColumns"
          :tableData="tableData"
          :tableLoading="tableLoading"
          :tableOptions="tableOptions"
          @page-size-change="handleSizeChange"
          @page-current-change="handleCurrentChange"
          @page-refresh-change="onLoad"
          @gird-handle-select-click="selectionChange"
          @grid-edit="handleEdit"

        >
        </grid-layout>

      </el-main>
    </el-container>








    <el-dialog
      :title="$t('datamodel.addForm.newtable')"
      :visible.sync="dialogVisible1"
      width="90%"
    >
      <div>
        <div>
          <el-form ref="form" :model="rightForm" :rules='rightRules' label-width="100px">
            <el-row :gutter="10">
              <el-col :span="5">
                <el-form-item :label="$t('datamodel.addForm.Modelname')">
                  <el-input v-model="activeModel.name" disabled size="mini"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item :label="$t('datamodel.addForm.datatype')">
                  <!--                  <el-input  v-model="rightForm.meiyou" ></el-input>-->
                  <!-- <el-input disabled  size="mini" v-model="source.dbtype"></el-input> -->
                  <el-input v-model="source.dbtype" disabled size="mini"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item :label="$t('datamodel.addForm.datasource')" prop="sourceId">
                  <el-select v-model="rightForm.sourceId" size="mini" @change="sourceChange">
                    <el-option v-for=' (item,index) in sourceList' :key="index" :label="item.desc" :value="item.id" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item :label="$t('datamodel.addForm.Tablename')" prop="tableName">
                  <el-input v-model="rightForm.tableName" size="mini"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item :label="$t('datamodel.addForm.notes')" prop="tableComment">
                  <el-input v-model="rightForm.tableComment" size="mini"></el-input>
                </el-form-item>
              </el-col>

            </el-row>
          </el-form>
          <el-tabs type="border-card">
            <el-tab-pane :label="$t('datamodel.label.form')">
              <div style="width: 120px;height:30px;margin:10px 0px;display: flex;float:right">
                <i class="el-icon-circle-plus-outline" style="font-size:20px;float:right;cursor: pointer" @click='addTableFn'></i>
                <i class=" el-icon-remove-outline" style="font-size:20px;float:right;cursor: pointer" @click='delTableFn'></i>

              </div>
              <el-table
                :data="rightForm.metadataColumnList"
                border
                style="width: 100%"
                @selection-change="handleSelectionChange1">
                <el-table-column
                  type="selection"
                  width="55">
                </el-table-column>
                <el-table-column
                  :label="$t('datamodel.label.Fieldname')"
                  prop="columnName"
                  width="180">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.columnName" size='mini'></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('datamodel.label.Chinesename')"
                  prop="columnComment"
                  width="150">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.columnComment" size='mini'></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('datamodel.label.type')"
                  prop="dataType">
                  <template slot-scope="scope">
                    <el-select v-model="scope.row.dataType" size="mini">
                      <el-option v-for="(item,index) in leixingSel" :key="item.value" :label="item.value_name" :value="item.value_name"></el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('datamodel.label.length')"
                  prop="dataLength"
                  width="100">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.dataLength" size='mini' type='number'></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('datamodel.label.accuracy')"
                  prop="dataPrecision"
                  width="100">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.dataPrecision" size='mini' type='number'></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('datamodel.label.Primarykeyornot')"
                  prop="columnNullable"
                  width="120">
                  <template slot-scope="scope">
                    <el-switch
                      v-model="scope.row.columnKey"
                      :active-text="$t('datamodel.label.is')"
                      :inactive-text="$t('datamodel.label.no')"
                      active-value="1"
                      inactive-value="0"
                    >
                    </el-switch>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('datamodel.label.Isitempty')"
                  prop="column_isnull"
                  width="120">
                  <template slot-scope="scope">
                    <el-switch
                      v-model="scope.row.columnNullable"
                      :active-text="$t('datamodel.label.is')"
                      :inactive-text="$t('datamodel.label.no')"
                      active-value="1"
                      inactive-value="0"
                    >
                    </el-switch>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane :label="$t('datamodel.label.relationship')">
              <div style="width: 120px;height:30px;margin:10px 0px;display: flex;float:right">
                <i class="el-icon-circle-plus-outline" style="font-size:20px;float:right;cursor: pointer" @click='addTableFn1'></i>
                <i class=" el-icon-remove-outline" style="font-size:20px;float:right;cursor: pointer" @click='delTableFn1'></i>
              </div>
              <el-table
                :data="rightForm.metadataTableLinkList"
                border
                style="width: 100%"
                @selection-change="handleSelectionChange2">
                <el-table-column
                  type="selection"
                  width="55">
                </el-table-column>
                <el-table-column
                  :label="$t('datamodel.label.Fieldname')"
                  prop="name"
                  width="180">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.name" size="mini"></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('datamodel.label.Currenttablename')"
                  prop="tableName"
                  width="180">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.tableName" size="mini"></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('datamodel.label.FieldName')"
                  prop="columnName">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.columnName" size="mini"></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('datamodel.label.Associatedtable')"
                  prop="linkTableName">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.linkTableName" size="mini"></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('datamodel.label.Associatedfield')"
                  prop="linkColumnName">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.linkColumnName" size="mini"></el-input>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button style="margin-right: 20px" @click="dialogVisible1 = false">{{ $t('cancelText') }}</el-button>
        <el-button type="primary" @click="createModelTableFn('form')">{{ $t('submitText') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import CommonTree from "@/views/components/com_tree/index"
import dialogHeadBtn from "@/views/components/layout/dialog-head-btn";
import formLayout from '@/views/components/layout/form-layout'
import {
  dictByDictCode,
  getMetadataTableById,
  getModelToMetadataTable,
  listAll,
  saveModelToMetadataTable,
  updataModelToMetadataTable
} from "@/api/dataAcquisition/DataServices";
import {getDataDirectoryAllByPid} from "@/api/dataAcquisition/DataAssets";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";

export default {
  components: {
    HeadLayout,
    GridLayout,
    dialogHeadBtn,
    formLayout,
    CommonTree
  },
  data() {
    return {
      searchTitle:'name',
      tableOptions: {

        customRowAddBtn: false,
        menuWidth: 100,
        column: [
          {
            label: this.$t('datamodel.label.ChineseNameOfData'),
            prop: "tableComment",
            cell: true,
          },
          {
            label: this.$t('datamodel.label.Dataname'),
            prop: "tableName",
            cell: true,
          },
          {
            label: this.$t('datamodel.label.datatype'),
            prop: "dbTypeName",
            cell: true,
          },
          {
            label: this.$t('datamodel.label.Creationtime'),
            prop: "createTime",
            cell: true,
          },
        ],
      },
      page: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0
      },
      tableName2: "",
      tableLoading: false,
      searchColumns: [
        {
          label: "",
          // prop: "tableName2",
          placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.field.account"),
          click: ({value, column}) => {
            // this.$message.success('click')
          }
        },


      ],
      gridRowBtn: [
        {
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "grid-edit",
          remark: 'edit',
          type: "text",
          icon: ""
        },


      ],
      source: {},
      filterText: '',
      input: '',
      title: '新建模型',
      multipleSelection1: [],
      multipleSelection2: [],
      dialogVisible1: false,
      tableSize: 'small',
      rightForm: {
        "modelId": 0,
        "type": 1,
        "dbType": 0,
        "sourceId": 0,
        "sourceName": "",
        "tableName": "",
        "tableComment": "",
        "metadataColumnList": [],
        "metadataTableLinkList": []
      },
      form: {
        "model_name": "",
        "db_type1": "",
        "db_source": "",
        "model_id": "",
        db_type: ''
      },
      treeData1: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      multipleSelection: [],
      tableData: [],
      total: 0,
      queryList: {
        size: 20,
        current: 1,
        tableName: '',
        modelId: '',
        type: 1
      },
      rightRules: {
        tableName: [
          {required: true, message: this.$t('datamodel.rules.rules1'), trigger: 'change'}
        ],
        sourceId: [
          {required: true, message: this.$t('datamodel.rules.rules2'), trigger: 'change'}
        ],
        tableComment: [
          {required: true, message: this.$t('datamodel.rules.rules3'), trigger: 'change'}
        ],
      },
      loading: false,
      headBtnOptions: [{
        label: this.$t(`cip.cmn.btn.addBtn`),
        emit: "head-add",
        type: "button",
        icon: ""
      }],
      activeModel: {},
      data: [],
      treeData: [],
      leixingSel: [],
      sourceList: []
    };
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.dept_add, false),
        viewBtn: this.vaildData(this.permission.dept_view, false),
        delBtn: this.vaildData(this.permission.dept_delete, false),
        editBtn: this.vaildData(this.permission.dept_edit, false)
      };
    },
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  created() {
    this.onLoad1()
    this.sourceListFn()
  },
  methods: {
    sourceChange(value) {

      let source = this.sourceList.find(item => item.db_id == value);
      this.source = source;
      dictByDictCode({dict_code: source.dbtype}).then(res => {
        this.leixingSel = res.data.data
      })

      this.rightForm.dbType = source.dbtype;
      this.rightForm.sourceName = source.name;
      console.log(source,'dddd',this.rightForm);
    },
    sourceListFn() {
      listAll({model_state:1}).then((res) => {
        this.sourceList = res.data.data;
        this.sourceList.forEach(element => {
          element.id = element.id.toString();
        });
      })
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    handleSelectionChange1(val) {
      this.multipleSelection1 = val;

    },
    handleSelectionChange2(val) {
      this.multipleSelection2 = val;
    },
    onLoad1() {
      getDataDirectoryAllByPid({}).then(res => {
        this.treeData1 = res.data.data;
      });
    },
    addTableFn() {
      let obj = {
        "columnName": "",
        "columnComment": "",
        "dataType": "",
        "dataLength": "",
        "dataPrecision": "",
        "columnKey": "",
        "columnNullable": 0
      }
      this.rightForm.metadataColumnList.push(obj);
      this.rightForm.metadataColumnList.forEach((item, index) => {
        item.myId = index + 1
      })
    },
    delTableFn() {
      let newArr = this.rightForm.metadataColumnList.filter((v) =>

        this.multipleSelection1.every((val) => val.myId != v.myId)
      )
      this.rightForm.metadataColumnList = newArr;
    },
    addTableFn1() {
      let obj = {
        "name": "",
        "tableName": "",
        "columnName": "",
        "linkTableName": "",
        "linkColumnName": ""
      }
      this.rightForm.metadataTableLinkList.push(obj);
      this.rightForm.metadataTableLinkList.forEach((item, index) => {
        item.myId = index + 1
      })
    },
    delTableFn1(item) {

      // rightForm.metadataColumnList


      let newArr = this.rightForm.metadataTableLinkList.filter((v) =>
        this.multipleSelection2.every((val) => val.myId != v.myId)
      )
      this.rightForm.metadataTableLinkList = newArr;



    },
    createModelTableFn(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$loading();
          if (this.rightForm.id) {
            updataModelToMetadataTable(this.rightForm).then((res) => {
              this.$message.success(this.$t('datamodel.msg.msg1'))
              this.onLoad();
              this.$loading().close();
              this.dialogVisible1 = false;
            })
          } else {
            saveModelToMetadataTable(this.rightForm).then((res) => {
              this.$message.success(this.$t('datamodel.msg.msg2'))
              this.onLoad();
              this.$loading().close();
              this.dialogVisible1 = false;
            })
          }
        } else {

          return false;
        }
      });

    },
    moelClickFn(item) {
      this.activeModel = item;
      this.queryList.modelId = item.id;
      this.onLoad();

    },
    onLoad() {

      // this.loading = true;
      getModelToMetadataTable(this.queryList).then(res => {
        this.loading = false;
        let obj = res.data.data;
        this.tableData = obj.dataList;
        this.total = obj.page.totalSize
      });
    },
    addFn() {
      if (!this.activeModel.id) {
        this.$message.error(this.$t('DataDirectory.msg.msg2'))
        return
      }
      this.sourceListFn()
      this.dialogVisible1 = true;
      this.source.dbtype = ''
      this.rightForm = {
        "modelId": 0,
        "type": 1,
        "dbType": '',
        "sourceId": '',
        "sourceName": "",
        "tableName": "",
        "tableComment": "",
        "metadataColumnList": [],
        "metadataTableLinkList": []
      }
      this.rightForm.modelId = this.activeModel.id;
    },
    handleEdit(item) {

      this.rightForm = item;
      getMetadataTableById({id: item.id}).then((res) => {
        res.data.data.metadataColumnList.forEach((item, index) => {
          item.myId = index + 1
        })

        res.data.data.metadataTableLinkList.forEach((item, index) => {
          item.myId = index + 1
        })
        this.rightForm = res.data.data;
        this.rightForm.id = item.id;
        this.rightForm.modelId = this.activeModel.id;
        let source = this.sourceList.find(val => val.id == this.rightForm.sourceId);
        this.source = source;

        //源代码点编辑报错 不知道逻辑，看着改的
        // this.source = res.data.data.dbTypeName
        this.source.dbtype = res.data.data.dbTypeName

        // this.source=this.sourceList.find(val=>val.id==item.id)
        dictByDictCode({dict_code: item.dbTypeName}).then(res => {
          this.leixingSel = res.data.data
          this.dialogVisible1 = true;

        })

      })
    },
    tzFn() {
      this.$router.push({
        path: '/DataServices/dataServicesType',
      })
    },
    deleteFn(index, item) {
      if (index) this.multipleSelection = [item];
      if (this.multipleSelection.length == 0) {
        this.$message.error('请选择删除数据')
        return
      }
      let ids = this.multipleSelection.map((item) => {
        return item.dict_id
      })
      this.$confirm(this.$t('taskScheduling.msg.msg1'), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // delDict(ids).then((res)=>{
        //   this.$message.success('删除成功');
        //   this.onLoad()
        // })
      }).catch(() => {

      });

    },
    czFn() {
      this.queryList.tableName = '';
      this.onLoad()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      this.page.pageSize = val.pageSize
      this.queryList.perPage = val.pageSize;
      this.onLoad()
    },
    handleCurrentChange(val) {
      this.page.currentPage = val.currentPage
      this.queryList.startIndex = val.currentPage;
      this.onLoad()
    },
    onLoad2() {
      // this.queryList.tableName = this.tableName2
      this.onLoad()
    },
  },

  mounted() {
  },
};
</script>
<style scoped>
.headers {
  background: #FFFFFF;
  padding: 14px 12px;
}

.headers ::v-deep .el-button {
  height: 28px;
}
</style>
<style scoped>
.el-button + .el-button {
  margin-left: 0px;
}

ul, li {
  padding: 0;
  margin: 0;
  list-style: none;

}

.leftItem {
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-size: 14px;
  line-height: 36px;

}

.leftItem:hover {
  background-color: #F5F7FA
}

.leftItemActive {
  color: #409eff;
  background-color: #F5F7FA
}

.simsIcon {
  margin-left: 5px;
}

.el-tabs__content {
  padding: 10px !important;
}

.outLine {
  text-decoration: underline
}

.new-sino-input {
  width: 200px;
  margin-right: 20px;
}
</style>
